$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import '~@fortawesome/fontawesome-free/scss/variables';
@import '~@fortawesome/fontawesome-free/scss/mixins';
@import '~@fortawesome/fontawesome-free/scss/core';
@import '~@fortawesome/fontawesome-free/scss/larger';
@import '~@fortawesome/fontawesome-free/scss/fixed-width';
//@import '~@fortawesome/fontawesome-free/scss/list';
//@import '~@fortawesome/fontawesome-free/scss/bordered-pulled';
//@import '~@fortawesome/fontawesome-free/scss/animated';
//@import '~@fortawesome/fontawesome-free/scss/rotated-flipped';
//@import '~@fortawesome/fontawesome-free/scss/stacked';
//@import '~@fortawesome/fontawesome-free/scss/icons';
//@import '~@fortawesome/fontawesome-free/scss/screen-reader';

@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

.#{$fa-css-prefix}-twitter-square:before { content: fa-content($fa-var-twitter-square); }
.#{$fa-css-prefix}-facebook-square:before { content: fa-content($fa-var-facebook-square); }
.#{$fa-css-prefix}-eye:before { content: fa-content($fa-var-eye); }
.#{$fa-css-prefix}-tint:before { content: fa-content($fa-var-tint); }
.#{$fa-css-prefix}-magic:before { content: fa-content($fa-var-magic); }
.#{$fa-css-prefix}-copy:before { content: fa-content($fa-var-copy); }
.#{$fa-css-prefix}-briefcase:before { content: fa-content($fa-var-briefcase); }
.#{$fa-css-prefix}-credit-card:before { content: fa-content($fa-var-credit-card); }
.#{$fa-css-prefix}-shopping-cart:before { content: fa-content($fa-var-shopping-cart); }
.#{$fa-css-prefix}-car-side:before { content: fa-content($fa-var-car-side); }
.#{$fa-css-prefix}-truck:before { content: fa-content($fa-var-truck); }
.#{$fa-css-prefix}-cloud-download-alt:before { content: fa-content($fa-var-cloud-download-alt); }
.#{$fa-css-prefix}-comments:before { content: fa-content($fa-var-comments); }
.#{$fa-css-prefix}-book:before { content: fa-content($fa-var-book); }
.#{$fa-css-prefix}-envelope:before { content: fa-content($fa-var-envelope); }
.#{$fa-css-prefix}-paypal:before { content: fa-content($fa-var-paypal); }
.#{$fa-css-prefix}-link:before { content: fa-content($fa-var-link); }
.#{$fa-css-prefix}-apple:before { content: fa-content($fa-var-apple); }
.#{$fa-css-prefix}-windows:before { content: fa-content($fa-var-windows); }
.#{$fa-css-prefix}-linux:before { content: fa-content($fa-var-linux); }
.#{$fa-css-prefix}-android:before { content: fa-content($fa-var-android); }
.#{$fa-css-prefix}-chevron-left:before { content: fa-content($fa-var-chevron-left); }
.#{$fa-css-prefix}-chevron-right:before { content: fa-content($fa-var-chevron-right); }
.#{$fa-css-prefix}-cogs:before { content: fa-content($fa-var-cogs); }
.#{$fa-css-prefix}-wrench:before { content: fa-content($fa-var-wrench); }
.#{$fa-css-prefix}-sitemap:before { content: fa-content($fa-var-sitemap); }
.#{$fa-css-prefix}-globe-americas:before { content: fa-content($fa-var-globe-americas); }
.#{$fa-css-prefix}-expand-alt:before { content: fa-content($fa-var-expand-alt); }
.#{$fa-css-prefix}-image:before { content: fa-content($fa-var-image); }
.#{$fa-css-prefix}-check-square:before { content: fa-content($fa-var-check-square); }
.#{$fa-css-prefix}-terminal:before { content: fa-content($fa-var-terminal); }
.#{$fa-css-prefix}-desktop:before { content: fa-content($fa-var-desktop); }
.#{$fa-css-prefix}-mobile-alt:before { content: fa-content($fa-var-mobile-alt); }
.#{$fa-css-prefix}-folder-open:before { content: fa-content($fa-var-folder-open); }
.#{$fa-css-prefix}-share-alt:before { content: fa-content($fa-var-share-alt); }
.#{$fa-css-prefix}-external-link-alt:before { content: fa-content($fa-var-external-link-alt); }
.#{$fa-css-prefix}-map-marker-alt:before { content: fa-content($fa-var-map-marker-alt); }
.#{$fa-css-prefix}-check:before { content: fa-content($fa-var-check); }
